import React, { useState, useEffect } from 'react';
import { 
  Home, Menu, X, Phone, Mail, MapPin, ArrowRight, 
  Code, Globe, Database, Cloud, Users, Shield,
  CheckCircle, ChevronRight, MessageSquare
} from 'lucide-react';

// Animations Component
const FadeInSection = ({ children }) => {
  const [isVisible, setVisible] = useState(false);
  const domRef = React.useRef();

  useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => setVisible(entry.isIntersecting));
    });
    observer.observe(domRef.current);
    return () => observer.disconnect();
  }, []);

  return (
    <div
      ref={domRef}
      className={`transition-opacity duration-1000 ${
        isVisible ? 'opacity-100' : 'opacity-0'
      }`}
    >
      {children}
    </div>
  );
};

// Statistics Component
const Stats = () => {
  const [count, setCount] = useState(0);
  const stats = [
    { number: '20+', label: 'Projects Completed' },
    { number: '10+', label: 'Happy Clients' },
    { number: '5+', label: 'Years Experience' },
    { number: '1', label: 'Global Offices' }
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCount(prev => (prev < 100 ? prev + 1 : prev));
    }, 20);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="bg-blue-600 py-16">
      <div className="max-w-7xl mx-auto px-4">
        <div className="grid grid-cols-2 md:grid-cols-4 gap-8 text-white text-center">
          {stats.map((stat, index) => (
            <div key={index} className="p-4">
              <div className="text-4xl font-bold mb-2">{stat.number}</div>
              <div className="text-sm opacity-80">{stat.label}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

// Navigation Component
const Navigation = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 50);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);
  
  return (
    <nav className={`fixed w-full z-50 transition-all duration-300 ${
      scrolled ? 'bg-white shadow-lg' : 'bg-transparent'
    }`}>
      <div className="max-w-7xl mx-auto px-4">
        <div className="flex justify-between h-16">
          <div className="flex items-center">
            <span className={`text-2xl font-bold ${
              scrolled ? 'text-blue-600' : 'text-white'
            }`}>
              We4Soft
            </span>
          </div>
          
          <div className="hidden md:flex items-center space-x-8">
            {['Home', 'Services', 'Projects', 'About', 'Contact'].map((item) => (
              <a
                key={item}
                href={`#${item.toLowerCase()}`}
                className={`${
                  scrolled ? 'text-gray-700' : 'text-white'
                } hover:text-blue-600 transition-colors`}
              >
                {item}
              </a>
            ))}
          </div>
          
          <div className="md:hidden flex items-center">
            <button
              onClick={() => setIsOpen(!isOpen)}
              className={`${scrolled ? 'text-gray-700' : 'text-white'}`}
            >
              {isOpen ? <X /> : <Menu />}
            </button>
          </div>
        </div>
        
        {isOpen && (
          <div className="md:hidden bg-white">
            <div className="px-2 pt-2 pb-3 space-y-1">
              {['Home', 'Services', 'Projects', 'About', 'Contact'].map((item) => (
                <a
                  key={item}
                  href={`#${item.toLowerCase()}`}
                  className="block px-3 py-2 text-gray-700 hover:text-blue-600"
                  onClick={() => setIsOpen(false)}
                >
                  {item}
                </a>
              ))}
            </div>
          </div>
        )}
      </div>
    </nav>
  );
};

// Hero Component
const Hero = () => (
  <div id="home" className="relative min-h-screen flex items-center justify-center bg-gradient-to-r from-blue-600 to-blue-800">
    <div className="absolute inset-0 bg-black opacity-50"></div>
    <div className="relative max-w-7xl mx-auto px-4 text-center text-white">
      <h1 className="text-5xl md:text-7xl font-bold mb-6 animate-fade-in">
        Transforming Ideas into
        <span className="text-blue-400"> Digital Reality</span>
      </h1>
      <p className="text-xl md:text-2xl mb-12 opacity-90">
        We deliver innovative IT solutions that drive business growth
      </p>
      <div className="space-x-4">
        <a
          href="#contact"
          className="bg-blue-600 text-white px-8 py-4 rounded-lg font-semibold hover:bg-blue-700 transition-colors"
        >
          Get Started
        </a>
        <a
          href="#projects"
          className="bg-white text-blue-600 px-8 py-4 rounded-lg font-semibold hover:bg-gray-100 transition-colors"
        >
          View Projects
        </a>
      </div>
    </div>
  </div>
);

// Services Component
const Services = () => {
  const services = [
    {
      icon: <Code size={40} />,
      title: 'Custom Software Development',
      description: 'Tailored solutions designed to meet your specific business needs'
    },
    {
      icon: <Globe size={40} />,
      title: 'Web Development',
      description: 'Responsive and modern web applications'
    },
    {
      icon: <Database size={40} />,
      title: 'Mobile App Development',
      description: 'Native and cross-platform mobile applications'
    },
    {
      icon: <Cloud size={40} />,
      title: 'Cloud Solutions',
      description: 'Scalable and secure cloud infrastructure services'
    },
    {
      icon: <Users size={40} />,
      title: 'IT Consulting',
      description: 'Expert guidance for digital transformation'
    },
    {
      icon: <Shield size={40} />,
      title: 'Cybersecurity',
      description: 'Comprehensive security solutions for your business'
    }
  ];

  return (
    <div id="services" className="py-20 bg-gray-50">
      <div className="max-w-7xl mx-auto px-4">
        <FadeInSection>
          <h2 className="text-4xl font-bold mb-4 text-center">Our Services</h2>
          <p className="text-gray-600 text-center mb-12 max-w-2xl mx-auto">
            We offer comprehensive IT solutions tailored to meet your business needs
          </p>
          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
            {services.map((service, index) => (
              <div
                key={index}
                className="bg-white p-6 rounded-lg shadow-lg hover:shadow-xl transition-shadow"
              >
                <div className="text-blue-600 mb-4">{service.icon}</div>
                <h3 className="text-xl font-semibold mb-4">{service.title}</h3>
                <p className="text-gray-600">{service.description}</p>
              </div>
            ))}
          </div>
        </FadeInSection>
      </div>
    </div>
  );
};

// Projects Component
const Projects = () => {
  const projects = [
    {
      title: 'Simplify VMS',
      description: 'Advanced credentialing technology for workforce management',
      image: 'https://www.simplifyvms.com/images/products/credentialing/credentialing.svg',
      link: 'https://www.simplifyvms.com/solutions/credentialing',
      tags: ['HR Tech', 'Enterprise', 'SaaS']
    },
    {
      title: 'Hemam',
      description: 'A healthcare platform connecting service providers with patients in UAE',
      image: 'https://www.hemam.ae/wp-content/uploads/2021/08/card-img22.png',
      link: 'https://www.hemam.ae',
      tags: ['Healthcare', 'Platform', 'UAE']
    },
    {
      title: 'Janata Stores',
      description: 'E-commerce platform for groceries and daily essentials',
      image: 'https://cdn.dribbble.com/userupload/14544245/file/original-c8b6195a207296b745389cb986836ec5.png?resize=2048x1536&vertical=center',
      tags: ['E-commerce', 'Retail', 'Mobile App']
    },
    
  ];

  return (
    <div id="projects" className="py-20">
      <div className="max-w-7xl mx-auto px-4">
        <FadeInSection>
          <h2 className="text-4xl font-bold mb-4 text-center">Our Projects</h2>
          <p className="text-gray-600 text-center mb-12 max-w-2xl mx-auto">
            Discover how we've helped businesses transform their digital presence
          </p>
          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
            {projects.map((project, index) => (
              <div
                key={index}
                className="bg-white rounded-lg overflow-hidden shadow-lg hover:shadow-xl transition-shadow"
              >
                <img
                  src={project.image}
                  alt={project.title}
                  className="w-full h-48 object-cover"
                />
                <div className="p-6">
                  <h3 className="text-xl font-semibold mb-2">{project.title}</h3>
                  <p className="text-gray-600 mb-4">{project.description}</p>
                  <div className="flex flex-wrap gap-2 mb-4">
                    {project.tags.map((tag, tagIndex) => (
                      <span
                        key={tagIndex}
                        className="bg-blue-100 text-blue-600 px-3 py-1 rounded-full text-sm"
                      >
                        {tag}
                      </span>
                    ))}
                  </div>
                  {project.link && (
                    <a
                      href={project.link}
                      className="text-blue-600 flex items-center hover:text-blue-700"
                    >
                      Visit Website <ArrowRight className="ml-2" size={16} />
                    </a>
                  )}
                </div>
              </div>
            ))}
          </div>
        </FadeInSection>
      </div>
    </div>
  );
};

// Testimonials Component
const Testimonials = () => {
  const testimonials = [
    {
      name: "Sarah Johnson",
      role: "CEO, TechCorp",
      content: "We4Soft delivered exceptional results. Their team's expertise and dedication transformed our business processes.",
      image: "https://ui-avatars.com/api/?name=Sarah+Johnson&background=random"
    },
    {
      name: "Michael Chen",
      role: "CTO, Innovation Labs",
      content: "The mobile app developed by We4Soft exceeded our expectations. Highly recommended for any tech project.",
      image: "https://ui-avatars.com/api/?name=Michael+Chen&background=random"
    },
    {
      name: "Emma Williams",
      role: "Director, HealthTech",
      content: "Professional team, excellent communication, and outstanding results. We4Soft is our go-to technology partner.",
      image: "https://ui-avatars.com/api/?name=Emma+Williams&background=random"
    }
  ];

  return (
    <div className="bg-gray-50 py-20">
      <div className="max-w-7xl mx-auto px-4">
        <FadeInSection>
          <h2 className="text-4xl font-bold mb-4 text-center">What Clients Say</h2>
          <p className="text-gray-600 text-center mb-12 max-w-2xl mx-auto">
            Don't just take our word for it
          </p>
          <div className="grid md:grid-cols-3 gap-8">
            {testimonials.map((testimonial, index) => (
              <div key={index} className="bg-white p-6 rounded-lg shadow-lg">
                <div className="flex items-center mb-4">
                  <img
                    src={testimonial.image}
                    alt={testimonial.name}
                    className="w-12 h-12 rounded-full mr-4"
                  />
                  <div>
                    <h3 className="font-semibold">{testimonial.name}</h3>
                    <p className="text-gray-600 text-sm">{testimonial.role}</p>
                  </div>
                </div>
                <p className="text-gray-600 italic">"{testimonial.content}"</p>
              </div>
            ))}
          </div>
        </FadeInSection>
      </div>
    </div>
  );
};

// Contact Component
const Contact = () => (
  <div id="contact" className="py-20 bg-white">
    <div className="max-w-7xl mx-auto px-4">
      <FadeInSection>
        <h2 className="text-4xl font-bold mb-4 text-center">Contact Us</h2>
        <p className="text-gray-600 text-center mb-12 max-w-2xl mx-auto">
          Get in touch with our team to discuss your next project
        </p>
        <div className="grid md:grid-cols-2 gap-8">
          <div className="bg-blue-600 p-8 rounded-lg text-white">
            <h3 className="text-2xl font-semibold mb-6">Let's Connect</h3>
            <div className="space-y-4">
              <p className="flex items-center">
                <Phone className="mr-4" /> +919356700291
              </p>
              <p className="flex items-center">
                <Mail className="mr-4" /> contact@we4soft.com
              </p>
              <p className="flex items-center">
                <MapPin className="mr-4" /> JAINAM ELYSIUM Sadan wadi, Bhandup West, Mumbai, Maharashtra 400078
              </p>
            </div>
            {/* Social media links section 
            <div className="mt-8">
              <h4 className="text-xl font-semibold mb-4">Follow Us</h4>
              <div className="flex space-x-4">
                {['LinkedIn', 'Twitter', 'Facebook'].map((social) => (
                  // eslint-disable-next-line jsx-a11y/anchor-is-valid
                  <a
                    key={social}
                    href="#"
                    className="bg-blue-500 p-2 rounded-full hover:bg-blue-400 transition-colors"
                  >
                    {social}
                  </a>
                ))}
              </div>
            </div>
            */}
          </div>
          <div className="bg-gray-50 p-8 rounded-lg">
            <form className="space-y-6">
              <div>
                <label className="block text-gray-700 mb-2">Name</label>
                <input
                  type="text"
                  className="w-full p-3 border rounded-lg focus:ring-2 focus:ring-blue-600 focus:border-transparent"
                  placeholder="Your name"
                />
              </div>
              <div>
                <label className="block text-gray-700 mb-2">Email</label>
                <input
                  type="email"
                  className="w-full p-3 border rounded-lg focus:ring-2 focus:ring-blue-600 focus:border-transparent"
                  placeholder="Your email"
                />
              </div>
              <div>
                <label className="block text-gray-700 mb-2">Message</label>
                <textarea
                  className="w-full p-3 border rounded-lg h-32 focus:ring-2 focus:ring-blue-600 focus:border-transparent"
                  placeholder="Your message"
                ></textarea>
              </div>
              <button
                type="submit"
                className="w-full bg-blue-600 text-white px-6 py-3 rounded-lg font-semibold hover:bg-blue-700 transition-colors"
              >
                Send Message
              </button>
            </form>
          </div>
        </div>
      </FadeInSection>
    </div>
  </div>
);

// Footer Component
const Footer = () => (
  <footer className="bg-gray-900 text-white py-12">
    <div className="max-w-7xl mx-auto px-4">
      <div className="grid md:grid-cols-4 gap-8">
        <div className="space-y-4">
          <h3 className="text-2xl font-bold">We4Soft</h3>
          <p className="text-gray-400">
            Delivering innovative IT solutions since 2020
          </p>
        </div>
        <div>
          <h4 className="text-lg font-semibold mb-4">Quick Links</h4>
          <div className="space-y-2">
            {['Home', 'Services', 'Projects', 'About', 'Contact'].map((item) => (
              <a
                key={item}
                href={`#${item.toLowerCase()}`}
                className="block text-gray-400 hover:text-white transition-colors"
              >
                {item}
              </a>
            ))}
          </div>
        </div>
        <div>
          <h4 className="text-lg font-semibold mb-4">Services</h4>
          <div className="space-y-2">
            {[
              'Custom Software',
              'Mobile Apps',
              'Web Development',
              'Cloud Solutions',
              'IT Consulting'
            ].map((service) => (
              <a
                key={service}
                href="#services"
                className="block text-gray-400 hover:text-white transition-colors"
              >
                {service}
              </a>
            ))}
          </div>
        </div>
        <div>
          <h4 className="text-lg font-semibold mb-4">Newsletter</h4>
          <p className="text-gray-400 mb-4">
            Subscribe to our newsletter for updates
          </p>
          <form className="space-y-4">
            <input
              type="email"
              placeholder="Your email"
              className="w-full p-2 rounded bg-gray-800 border border-gray-700 focus:ring-2 focus:ring-blue-600 focus:border-transparent"
            />
            <button
              type="submit"
              className="w-full bg-blue-600 text-white px-4 py-2 rounded font-semibold hover:bg-blue-700 transition-colors"
            >
              Subscribe
            </button>
          </form>
        </div>
      </div>
      <div className="mt-8 pt-8 border-t border-gray-800 text-center text-gray-400">
        <p>&copy; 2024 We4Soft. All rights reserved.</p>
      </div>
    </div>
  </footer>
);

// Main App Component
const App = () => (
  <div className="min-h-screen">
    <Navigation />
    <Hero />
    <Stats />
    <Services />
    <Projects />
    <Testimonials />
    <Contact />
    <Footer />
  </div>
);

export default App;